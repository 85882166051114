import { required } from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'
import i18n from '@/i18n'

extend('mandatory', {
  ...required,
  params: ['type'],
  validate (value, { type }) {
    if (type === 'MA') {
      return {
        required: true,
        valid: typeof value === 'object' && value.length > 0
      }
    }
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    }
  },
  message: (_, values) => i18n.t('validations.messages.mandatory', values)
})

extend('between', {
  params: ['type', 'min', 'max'],
  validate (value, { type, min, max }) {
    if (type === 'MA') {
      return typeof value === 'object' && value.length >= min && value.length <= max
    }
    return true
  },
  message: (_, values) => i18n.t('validations.messages.between', values)
})

extend('min', {
  params: ['type', 'min'],
  validate (value, { type, min }) {
    if (type === 'MA') {
      return typeof value === 'object' && value.length >= min
    }
    return true
  },
  message: (_, values) => i18n.t('validations.messages.min', values)
})

extend('max', {
  params: ['type', 'max'],
  validate (value, { type, max }) {
    if (type === 'MA') {
      return typeof value === 'object' && value.length <= max
    }
    return true
  },
  message: (_, values) => i18n.t('validations.messages.max', values)
})
