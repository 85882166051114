import { MULTIPLE_ANSWER, SINGLE_ANSWER_MATRIX } from '@/constants/question-type'

const OR = 'OR'
const AND = 'AND'
const Equal = '='
const NotEqual = '!='

export const questionKey = (qid, sqcode, acode) => {
  const keyArray = [qid]
  if (sqcode || acode) keyArray.push(sqcode, acode)
  return keyArray.join('_')
}

export const evaluateCondition = (a, b, operator) => {
  switch (operator) {
    case Equal:
      return a == b
    case NotEqual:
      return a != b
  }
}

export const checkConditions = (conditions, result) => {
  if (!conditions || typeof conditions !== 'object') return true

  if (conditions.qid) {
    let key = questionKey(conditions.qid), value = conditions.value
    switch (conditions.type) {
      case MULTIPLE_ANSWER:
        key = questionKey(conditions.qid, null, conditions.value)
        value = '1'
        break
      case SINGLE_ANSWER_MATRIX:
        key = questionKey(conditions.qid, null, conditions.value)
    }
    return evaluateCondition(result[key], value, conditions.operator)
  }

  if (conditions?.logic && conditions?.rules) {
    switch (conditions.logic) {
      case OR:
        for (let i = 0; i < conditions.rules.length; i++) {
          if (checkConditions(conditions.rules[i], result)) {
            return true
          }
        }
        return false
      case AND:
        for (let i = 0; i < conditions.rules.length; i++) {
          if (!checkConditions(conditions.rules[i], result)) {
            return false
          }
        }
        return true
      default:
        return true
    }
  }

  return true
}
