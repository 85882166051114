import axios from '../utils/axios'

const VERSION = 'v1'

const joinPath = (...agrs) => [VERSION, ...agrs].join('/')

export const startSurvey = (surveyCode, params) => {
  return axios.get(joinPath('surveys', surveyCode, 'start'), { params })
}

export const getSurveyFull = (surveyCode, params) => {
  return axios.get(joinPath('surveys', surveyCode, 'full'), { params })
}

export const back = (surveyCode, data) => {
  return axios.post(joinPath('surveys', surveyCode, 'back'), data)
}

export const submit = (surveyCode, data) => {
  return axios.post(joinPath('surveys', surveyCode, 'submit'), data)
}

export const getPage = (pid) => {
  return axios.get(joinPath('pages', pid))
}

export const getQuestion = (qid) => {
  return axios.get(joinPath('questions', qid))
}

export const getSurvey = (surveyCode) => {
  return axios.get(joinPath('surveys', surveyCode))
}

export const getSurveyPage = (surveyCode, pageCode) => {
  return axios.get(joinPath('surveys', surveyCode, 'pages', pageCode))
}

export const getSurveyQuestion = (surveyCode, questionCode) => {
  return axios.get(joinPath('surveys', surveyCode, 'questions', questionCode))
}

export const getPolicy = (lang) => {
  return axios.get(joinPath('policy'), { params: { lang } })
}
