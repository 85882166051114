export const QuestionType = {
  single_answer: 'SA',
  single_answer_matrix: 'SAM',
  multiple_answer: 'MA',
  select_language: 'SL',
  dropdown_list: 'DL',
  satisfaction: 'S',
  free_answer: 'FA',
  date_time: 'DT',
  text_display: 'TD',
  numerical_input: 'NI',
  screen_out: 'SO',
}

export const SINGLE_ANSWER = 'SA'
export const SINGLE_ANSWER_MATRIX = 'SAM'
export const MULTIPLE_ANSWER = 'MA'
export const SELECT_LANGUAGE = 'SL'
export const DROPDOWN_LIST = 'DL'
export const SATISFACTION = 'S'
export const FREE_ANSWER = 'FA'
export const DATE_TIME = 'DT'
export const TEXT_DISPLAY = 'TD'
export const NUMERICAL_INPUT = 'NI'
export const SCREEN_OUT = 'SO'
