import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Buefy from 'buefy'
import '@/assets/styles/app.scss'
import i18n from './i18n'
import { configure, ValidationObserver, ValidationProvider } from 'vee-validate'
import './vee-validate'

Vue.use(Buefy)

Vue.config.productionTip = false

configure({
  // this will be used to generate messages.
  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`fields.${field}`)
    return i18n.t(`validations.messages.${values._rule_}`, values)
  }
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#survey')
