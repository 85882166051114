import Vue from 'vue'
import Vuex from 'vuex'
import { back, startSurvey, submit } from '@/api/response'
import { checkConditions } from '@/utils/question'

Vue.use(Vuex)

export const SET_CONNECTED = 'SET_CONNECTED'
export const SET_LOADING = 'SET_LOADING'
export const SET_SURVEY = 'SET_SURVEY'
export const SET_PAGE = 'SET_PAGE'
export const SET_RESPONSE = 'SET_RESPONSE'
export const UPDATE_STATE = 'UPDATE_STATE'
export const UPDATE_CHANGES = 'UPDATE_CHANGES'

export const START_SURVEY = 'startSurvey'
export const BACK = 'BACK'
export const NEXT = 'NEXT'

export default new Vuex.Store({
  state: {
    connected: navigator.onLine,
    loading: {},
    survey: {},
    page: {},
    response: {},
  },
  getters: {
    connected: (state) => state.connected,
    loading: (state) => (code) => state.loading[code] || false,
    survey: (state) => (code) => state.survey[code],
    page: (state) => (code) => state.page[code],
    questions: (state, getters) => (code) => {
      const page = getters.page(code)
      const questions = page?.questions
      if (!page && !questions) return []
      return questions.filter(question => checkConditions(question.conditions, getters.result(code)))
    },
    response: (state) => (code) => state.response[code],
    previousStates: (state, getters) => (code) => getters.response(code)?.previous_states || {},
    currentState: (state, getters) => (code) => getters.response(code)?.current_state || {},
    result: (state, getters) => (code) => {
      const response = getters.response(code)
      return {
        ...response?.result,
        ...response?.current_state?.changes,
      }
    },
  },
  mutations: {
    [SET_CONNECTED] (state, payload) {
      state.connected = payload
    },
    [SET_LOADING] (state, { code, loading }) {
      Vue.set(state.loading, code, loading)
    },
    [SET_SURVEY] (state, survey) {
      Vue.set(state.survey, survey.code, survey)
    },
    [SET_PAGE] (state, { code, page }) {
      Vue.set(state.page, code, page)
    },
    [SET_RESPONSE] (state, { code, response }) {
      Vue.set(state.response, code, response)
    },
    [UPDATE_STATE] (state, { code, current_state }) {
      const oldState = state.response[code]?.current_state
      Vue.set(state.response[code], 'current_state', { ...oldState, ...current_state, })
    },
    [UPDATE_CHANGES] (state, { code, changes }) {
      const currentChanges = state.response[code]?.current_state?.changes
      Vue.set(state.response[code].current_state, 'changes', { ...currentChanges, ...changes })
    },
  },
  actions: {
    async [START_SURVEY] ({ commit }, { code, ...params }) {
      const {
        data: { data },
      } = await startSurvey(code, params)
      const { survey, page, response } = data
      commit(SET_SURVEY, survey)
      commit(SET_PAGE, { code, page })
      commit(SET_RESPONSE, { code, response })
    },
    async [BACK] ({ commit }, { code, data }) {
      commit(SET_LOADING, { code, loading: true })
      try {
        commit(UPDATE_CHANGES, { code, changes: data?.result })
        const {
          data: { data: surveyData },
        } = await back(code, data)
        const { survey, page, response } = surveyData
        commit(SET_SURVEY, survey)
        commit(SET_PAGE, { code, page })
        commit(SET_RESPONSE, { code, response })
      } finally {
        commit(SET_LOADING, { code, loading: false })
      }
    },
    async [NEXT] ({ commit }, { code, data }) {
      commit(SET_LOADING, { code, loading: true })
      try {
        commit(UPDATE_CHANGES, { code, changes: data?.result || {} })
        const {
          data: { data: surveyData },
        } = await submit(code, data)
        const { survey, page, response } = surveyData
        commit(SET_SURVEY, survey)
        commit(SET_PAGE, { code, page })
        commit(SET_RESPONSE, { code, response })
      } finally {
        commit(SET_LOADING, { code, loading: false })
      }
    },
  },
})
